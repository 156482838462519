.container {
  padding: 40px 80px;
}

.dash-header {
  margin: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dash-header h1 {
  margin: 20px 0;
}

.return {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.return-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-light);
  color: var(--blue-dark);
  margin-right: 20px;
  width: 35px;
  height: 35px;
}

.return p {
  font-weight: 600;
}
/* Account Dashboard */

.dash-main-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dash-personal-details {
  width: 50%;
  padding: 0 20px;
}

.dash-nav-wrapper {
  width: 50%;
  padding: 30px 20px;
}

.dash-nav {
  background: var(--blue-light);
  border-radius: 25px;
  padding: 20px 30px;
}

.dash-nav-items {
  padding-bottom: 10px;
}

.dash-nav-item {
  background: white;
  padding: 15px 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.dash-nav-item:hover {
  background: var(--blue);
  cursor: pointer;
  color: white;
}

.dash-icon-wrapper {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-light);
  width: 30px;
  height: 30px;
  color: var(--blue-dark);
}

.deactivate-account {
  text-align: right;
  color: var(--red);
}

.deactivate-account p {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
}
.dash-advice {
  text-align: right;
  margin: 20px 0;
  max-width: 500px;
  margin-left: auto;
}

.view-plans {
  text-decoration: underline;
}

.plan {
  font-weight: 700;
}
/* Tablet Portrait*/
@media (max-width: 1366px) {
  .container {
    padding: 40px 40px;
  }
  .dash-main-container {
    flex-direction: column;
  }

  .dash-personal-details,
  .dash-nav-wrapper {
    width: 100%;
    margin: 20px 0;
  }
  .dash-personal-details {
    margin-top: 0;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .container {
    padding: 10px 10px;
  }

  .dash-nav-wrapper {
    padding: 10px 10px;
  }

  .dash-nav {
    padding: 20px 20px;
  }

  .dash-advice {
    text-align: right;
    margin: 40px 10px;
  }
}

/* Billing And Payment */
.container {
  position: relative;
}

.billing-main-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.payment-methods {
  width: 60%;
  padding: 0px 20px;
}
.methods-container {
  margin: 40px 0;
}

.methods-container:first-child {
  margin-top: 0;
}
.method-label {
  font-weight: 600;
  margin: 10px 0;
}
.card-container {
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 10px 0;
}
.card-details {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-info {
  padding: 0 15px;
}

.card-icon {
  color: var(--blue);
  font-size: 25px;
}
.card-number {
  margin: 5px 0;
  font-weight: 600;
}

.make-default {
  margin-left: auto;
  margin-right: 20px;
}

.make-default p {
  text-decoration: underline;
  color: var(--blue);
  cursor: pointer;
}

.remove-payment-method {
  color: var(--red);

  cursor: pointer;
}

.billing-actions-wrapper {
  padding: 30px 20px;
  width: 40%;
}
.billing-actions {
  padding: 30px 20px;
  background: var(--blue-light);
  border-radius: 25px;
}
.bill-plan-item {
  margin: 20px 0;
  cursor: pointer;
}
.bill-plan-label {
  font-weight: 600;
  margin: 10px 0;
}
.bill-plan-container {
  display: flex;
  align-items: space-between;
  justify-content: center;
  background: white;
  padding: 20px;
  border-radius: 15px;
}

.bill-plan-container p {
  width: 100%;
  text-align: center;
}

.billing-button {
  margin: 20px 0;
}

.billing-button button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.loading-container {
  width: 60%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Tablet Portrait*/
@media (max-width: 1366px) {
  .billing-main-container {
    flex-direction: column;
  }

  .payment-methods {
    width: 100%;
    padding: 0;
  }
  .billing-actions-wrapper {
    width: 100%;
    margin-top: 20px;
    padding: 0;
  }
  .loading-container {
    width: 100%;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .payment-methods,
  .billing-actions-wrapper {
    padding: 0 10px;
  }

  .card-info,
  .make-default {
    font-size: 14px;
  }
}

/* Invoice History */

.invoice-table-container {
  width: 100%;
}

.invoice-table {
  width: 100%;
}

.invoice-head-cell {
  text-align: left;
  color: var(--blue);
  padding: 10px;
}

.invoice-row {
  box-shadow: var(--box-shadow);
  border-radius: 5px;
}

.invoice-row:hover {
  background: var(--hover);
}

.invoice-cell {
  padding: 10px;
}

.download {
  text-align: center;
  width: 200px;
}

.only-desktop {
  padding: 20px;
  text-align: center;
}
