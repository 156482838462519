.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  cursor: pointer;
}

.icon {
  margin: 0 20px 0 0;
  width: 30px;
}

.link:hover {
  text-decoration: underline;
}
