.status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 20px;
}

.status-container p {
  font-weight: 500;
  font-size: 18px;
}

.status-icon.pending {
  background: var(--orange);
}

.status-icon.draft {
  background: var(--blue);
}

.status-icon.success {
  background: var(--green);
}

.status-icon.failed {
  background: var(--red);
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
}
/* Mobile */
@media (max-width: 768px) {
  .status-container p {
    font-size: 14px;
  }
}
