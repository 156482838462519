.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 400ms ease;
  background: white;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  padding: 20px;
  min-width: 50vw;
  border-radius: 5px;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.modal-header {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
}

.modal-header svg {
  width: 20px;
  height: 100%;
  cursor: pointer;
}
.label {
  font-weight: 600;
}

.modal-divider {
  margin: 20px 0 0 0;
  border-bottom: 1px solid var(--border);
}

.modal-content {
  max-height: 65vh;
  overflow: scroll;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
}
/* Mobile */
@media (max-width: 768px) {
}
