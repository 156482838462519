.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;
}

.display-values {
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-box {
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container p {
  font-weight: 500;
}

.value-outline {
  border: 1px solid var(--blue);
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.disabled {
  opacity: 0.3;
}

.check {
  color: var(--blue);
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
}
/* Mobile */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .container p {
    text-align: center;
  }
  .display-values {
    margin: 20px 0;
  }
}
