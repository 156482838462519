.checkbox {
  width: 15px;
  height: 15px;
  transform: translateY(2px);
}

.label {
  margin-left: 15px;
}

.message {
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  bottom: -16px;
  left: 10px;
  color: var(--error-dark);
}

.disabled {
  color: rgb(170, 170, 170) !important;
}

@media (max-width: 768px) {
  .label {
    font-size: 14px;
  }
}
