.analytics {
  background: white;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 200ns ease;
  position: relative;
}

.analytics:hover {
  cursor: pointer;
}

.analytics.success {
  grid-area: 7 / 1 / 11 / 1;
}

.analytics.pending {
  grid-area: 7 / 2 / 11 / 2;
}
.analytics.failed {
  grid-area: 7 / 3 / 11 / 3;
}

.container {
  height: 120px;
  width: 120px;
  background: var(--blue-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.container.success {
  background: var(--green-light);
  border: 2px solid var(--green);
  color: var(--green);
}

.container.pending {
  background: var(--orange-light);
  border: 2px solid var(--orange);
  color: var(--orange);
}

.container.failed {
  background: var(--red-light);
  border: 2px solid var(--red);
  color: var(--red);
}

.container p {
  font-size: 30px;
  font-weight: 500;
}

.text {
  text-align: center;
  font-weight: 500;
  width: 70%;
  margin: 0 auto;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(45deg);
}

.success .triangle {
  border-bottom: 10px solid var(--green);
}

.pending .triangle {
  border-bottom: 10px solid var(--orange);
}

.failed .triangle {
  border-bottom: 10px solid var(--red);
}
