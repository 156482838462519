/* Return Home */
.return-container {
  position: absolute;
  left: 80px;
  top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
}

.return-icon {
  background-color: var(--blue-light);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue-dark);
  margin-right: 40px;
}

/* New Form */
.new-content {
  padding: 40px 20px;
  margin-top: 80px;
}

.new-header {
  padding: 0 140px;
}
.new-form-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.new-left,
.new-right {
  width: 50%;
  padding: 0 20px;
}

.new-save-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  margin-top: 25px;
}

.new-info-container {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid var(--blue);
  margin-right: 15px;
}

.new-info-container p {
  font-size: 12px;
  color: var(--blue);
  font-weight: 500;
}

.new-checkbox-bold {
  font-weight: 700;
}

.exp-cvv-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 60px 0 25px 0;
}

.expiry-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 25px;
}

.expiry-input {
  width: 150px;
}
.expiry-input:first-child {
  margin-right: 15px;
}

.form-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 100px;
}

.form-buttons button {
  margin: 20px;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .new-content {
    padding: 20px;
    margin-top: 20px;
  }

  .new-header {
    padding: 0 40px;
  }

  .new-form-container {
    padding: 20px;
    flex-direction: column;
  }

  .new-left,
  .new-right {
    width: 100%;
  }

  .new-checkbox-bold {
    margin-bottom: 40px !important;
  }

  .form-buttons {
    justify-content: center;
  }
  .return-container {
    display: none;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .return-container {
    display: none;
  }

  .new-content {
    padding: 0;
    margin-top: 20px;
  }

  .new-form-container {
    padding: 20px 0;
  }

  .new-header {
    padding: 0 40px;
    font-size: 12px;
  }
  .form-buttons {
    flex-direction: column;
    padding-bottom: 20px;
  }

  .form-buttons button {
    margin: 10px;
  }
}
