.input-container {
  width: 100%;
  position: relative;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 15px 20px;
  background: var(--input-background);
}

.background {
  background: var(--input-background-dark);
}

.box-shadow-grey {
  box-shadow: var(--box-shadow);
}

.input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  background: none;
}

.input::placeholder {
  color: var(--grey);
  font-weight: 300;
}

.input-container:focus-within {
  border: 1px solid var(--blue);
}

.error {
  border: 1px solid var(--error-dark) !important;
  background: var(--error-light) !important;
}

.error .input {
  color: var(--error-dark);
}

.label {
  font-size: 18px;
  font-weight: 500;
  top: -35px;
  left: 10px;
  position: absolute;
}

.error .label {
  color: var(--error-dark);
}

.message {
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  bottom: auto;
  top: -25px;
  right: 0;
  left: auto;
  color: var(--error-dark);
}

.link {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: auto;
  top: -30px;
  right: 0;
  left: auto;
  text-decoration: underline;
  color: var(--blue);
}

.disabled {
  color: rgb(170, 170, 170) !important;
  background: var(--disabled-background) !important;
}
@media (max-width: 768px) {
  .input {
    font-size: 14px;
  }

  .label {
    font-size: 14px;
    top: -25px;
  }

  .message {
    bottom: auto;
    top: -25px;
    right: 0;
    left: auto;
    text-align: left;
    white-space: nowrap;
  }
}
