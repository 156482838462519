.container {
  display: flex;
  flex-direction: column;
  height: auto;
  box-shadow: var(--box-shadow);
  margin: 20px;
  max-width: 500px;
  position: relative;
}

.header {
  padding: 30px 30px;
  min-height: 45vh;
}

.header h1 {
  margin: 20px 0;
  font-size: 45px;
  font-weight: 600;
}

.header h4 {
  margin: 20px 0;
  color: var(--blue);
  font-weight: 500;
  font-size: 17px;
}

.application-container {
  border-top: solid 1px var(--border);
  border-bottom: solid 1px var(--border);
  padding: 30px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.button-item {
  padding: 15px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  background: var(--blue);
  color: white;
  padding: 15px 30px;
  border-radius: 15px 15px 0 0;
}

.description {
  line-height: 1.8;

}

/* Tablet Portrait*/
@media (max-width: 1366px) {
}

/* Mobile */
@media (max-width: 768px) {
  .container {
    height: auto;
    margin: 20px 20px;
  }

  .container:first-child {
    margin-top: 0;
  }

  .banner {
    display: none;
  }
}
