.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  z-index: 999;
}

.left,
.right {
  background: var(--blue);
  width: 50%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  transition: all 0.2s ease-in-out;
}

.openUp.left {
  transform: translateX(-100%);
}

.openUp.right {
  transform: translateX(100%);
}

.hideLoader {
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, 50%);
}

.spinner-container {
  position: relative;
}

.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
  display: block;
}
.loader:before {
  content: "";
  width: 48px;
  height: 5px;
  background: #000;
  opacity: 0.25;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}

.loader:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  animation: bxSpin 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}
.loader.blue:after {
  background: var(--blue);
}

@keyframes bxSpin {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {
  0%,
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}
