.button {
  gap: 7px;
  border: none;
  border-radius: 10px;
  color: white;
  background: var(--blue);
  padding: 10px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  cursor: pointer;
  white-space: nowrap;
}

.button:disabled {
  background: var(--grey) !important;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content p {
  transform: translateY(-1px);
}
.blue {
  background: var(--blue);
  color: white;
}

.blue:hover {
  background: var(--blue-dark);
}

.blue-light {
  background: var(--blue-light);
  color: var(--blue-dark);
}

.blue-light:hover {
  background: var(--blue);
  color: white;
}

.blue-dark {
  background: var(--blue-dark);
  color: white;
}

.blue-dark:hover {
  background: var(--blue);
  color: white;
}

.white {
  color: var(--blue);
  background: white;
}

.white:hover {
  color: white;
  background: var(--blue);
}

.red {
  background: var(--red);
  color: white;
}

.red:hover {
  background: var(--red-dark);
}

.small {
  padding: 6px 45px;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
}

.medium {
  padding: 10px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.large {
  padding: 15px 65px;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

.large .icon {
  margin-left: 15px;
}

.icon {
  width: 12px;
  height: 12px;
  margin-left: 8.5px;
}

@media (max-width: 768px) {
  .button {
    font-size: 14px;
  }
}
