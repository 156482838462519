/* Dashbooard */

.container {
  display: flex;
  align-items: flex-start;
  height: calc(100% - 80px);
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .container {
    height: auto;
    flex-direction: column;
  }
}

/* Mobile */
@media (max-width: 768px) {
}

/* Analytics */

.analytics-container {
  background: var(--blue-light);
  height: 100%;
  width: 40%;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
}

.analytics-heading-container h1 {
  margin-bottom: 30px;
}

.analytics-heading-container h1 span {
  color: white;
  background: var(--blue);
  border-radius: 10px;
  padding: 2px 35px;
  margin-left: 15px;
}
.analytics-heading-container p {
  width: 75%;
  line-height: 160%;
}

.analytics-dashboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, 1fr);
  gap: 20px;
  flex: 1;
  margin-top: 40px;
}

.analytics-card {
  background: white;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.analytics-timeline {
  grid-area: 1 / 1 / 7 / 4;
  display: flex;
  flex-direction: column;
}

.analytics-success {
  grid-area: 7 / 1 / 11 / 1;
}

.analytics-pending {
  grid-area: 7 / 2 / 11 / 2;
}
.analytics-failed {
  grid-area: 7 / 3 / 11 / 3;
}

.timeline-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 15px 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--light);
}

.timeline-heading p {
  font-size: 12px;
  font-weight: 800;
}

.timeline-container {
  flex: 1;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .analytics-container {
    width: 100%;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .analytics-container {
    padding: 20px;
  }

  .analytics-heading-container p {
    text-align: center;
    margin: 0 auto;
    width: 95%;
  }

  .analytics-heading-container h1 {
    margin-bottom: 10px;
    transform: translateX(10px);
  }
  .analytics-dashboard {
    display: none !important;
  }
}

/* Progress */
.progress-container {
  height: 100%;
  width: 60%;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
}

.progress-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-heading h1 {
  margin-left: 20px;
}

.progress-status-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.progress-grid {
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 15px;
  padding: 20px 0;
}

.no-applications {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-applications p {
  color: var(--blue-dark);
  opacity: 0.4;
  text-align: center;
}

.loading-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .progress-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .progress-container {
    width: 100%;
    min-height: 70vh;
  }
  .progress-heading {
    position: relative;
  }
  .progress-heading h1 {
    font-size: 22px;
    margin-left: 15px;
  }

  .progress-view-applications {
    position: absolute;
    top: -5px;
    right: 0%;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .progress-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .progress-view-applications {
    display: none;
  }

  .progress-container {
    padding: 20px;
  }
  .progress-heading h1 {
    font-size: 20px;
    margin-left: 15px;
  }

  .progress-status-container {
    margin-top: 10px;
  }
}
