.user-container {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  margin: 5px 0;
  font-weight: 500;
}

.name span {
  font-weight: 200;
  margin-left: 20px;
  font-size: smaller;
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--red);
  cursor: pointer;
}

.delete p {
  margin-right: 10px;
}
