.container {
  width: 100%;
  position: relative;
  border: 1px solid transparent;
  border-radius: 10px;
  background: var(--white);
}

.box-shadow-grey {
  box-shadow: var(--box-shadow);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  height: 54px;
  border-radius: 10px;
}

.hover:hover {
  cursor: pointer;
  background: var(--hover);
}

.placeholder {
  color: var(--grey);
  font-weight: 300;
}

.label {
  font-size: 18px;
  font-weight: 500;
  top: -35px;
  left: 10px;
  position: absolute;
}

.icon {
  width: 15px;
  height: 15px;
  margin-left: 15px;
  color: var(--blue);
  transform: translateY(1px);
}

.content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  transform: scaleY(0);
  background: white;
  z-index: 99;
  box-shadow: var(--box-shadow);
  transition: 200ms ease all;
  transform-origin: top;
  max-height: 200px;
  overflow: scroll;
  border-radius: 0 0 15px 15px;
}

.content::-webkit-scrollbar {
  display: none;
}

.open {
  transform: scaleY(1);
}

.option {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid var(--border);
}

.option:hover {
  background: var(--hover);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 98;
}

.error {
  border: 1px solid var(--error-dark) !important;
  background: var(--error-light) !important;
}

.error .input {
  color: var(--error-dark);
}
.message {
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  bottom: auto;
  top: -25px;
  right: 0;
  left: auto;
  color: var(--error-dark);
}

.disabled {
  color: rgb(170, 170, 170) !important;
  background: var(--hover);
  box-shadow: none;
}

.search-input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  background: transparent;
}
@media (max-width: 768px) {
  .label {
    font-size: 14px;
    top: -25px;
  }

  .message {
    bottom: -20px;
  }

  .wrapper {
    height: 48px;
  }
}
