.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100vh - 80px);
}

/* Team Container */
.team-container {
  height: 100%;
  overflow: hidden;
  padding: 40px;
  width: 35%;
  display: flex;
  flex-direction: column;
}

.team-container h1 {
  margin: 20px 0;
}

.team-container h1 span {
  color: var(--blue);
}

.team-inner-container {
  border-radius: 25px;
  padding: 20px;
  border: 2px solid var(--blue);
  box-shadow: var(--box-shadow-blue);
  margin: 40px 0 0px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.team-heading {
  padding: 20px;
  border-bottom: 1px solid var(--border);
}

.team-total-application {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team-heading h4 {
  font-weight: 100;
  margin: 20px 0;
}

.team-content {
  padding: 20px;
  flex: 1;
  border-bottom: 1px solid var(--border);
}

.team-footer {
  padding: 20px;
}

.download-button {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue);
  color: white;
  border-radius: 5px;
  margin: 20px auto;
  cursor: pointer;
  border: 1px solid var(--blue);
}

.download-button:hover {
  background: var(--blue-light);
  color: var(--blue);
}

.download-button * {
  font-weight: 600;
}
.download-button p {
  margin: 0 20px;
}

/* Select Container */
.select-container {
  width: 65%;
  background: var(--blue-light);
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.select-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-header-title h1 {
  margin: 20px;
}

.select-header-title h3 {
  margin: 20px;
}

.select-header-title h3 span:first-child {
  color: var(--blue);
  margin: 0 20px 0 0;
}
.select-header-title h3 span:last-child {
  font-size: small;
  font-weight: 400;
  margin: 0 4px;
}

.select-header-connect {
  cursor: pointer;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.org-list-container {
  flex: 1;
  padding: 20px 0;
  overflow: auto;
}

.no-orgs-connected h3 {
  color: var(--blue-dark);
  font-weight: 500;
  padding: 0 20px;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
}

/* Mobile */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
  }

  .team-container {
    width: 100%;
    padding: 20px;
    overflow: visible;
    height: auto;
  }

  .select-container {
    width: 100%;
    padding: 20px;
    height: auto;
  }

  .select-header {
    flex-direction: column;
  }

  .select-header h1 {
    font-size: 20px;
    text-align: center;
  }

  .select-header h3 {
    font-size: 14px;
    text-align: center;
  }

  .select-header-title h3 span:last-child {
    font-size: 10px;
  }
}
