.container {
  display: flex;
  flex-direction: column;
  padding: 40px 60px 20px 60px;
  min-height: calc(100vh - 80px);
}
.header-legend h1 {
  margin-left: 20px;
}

.progress-status-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.progress-status {
  margin: 20px 0;
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.progress-status span {
  margin-right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
}

.progress-status:nth-child(1) span {
  background: var(--green);
}

.progress-status:nth-child(2) span {
  background: var(--orange);
}

.progress-status:nth-child(3) span {
  background: var(--red);
}

.progress-status:nth-child(4) span {
  background: var(--blue);
}

.applications {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 20px;
}
.no-applications {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination {
  margin-top: 20px;
}

.loading-container {
  flex: 1;
  display: flex;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .applications {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}

/* Mobile */
@media (max-width: 768px) {
  .container {
    padding: 20px 20px 20px 20px;
  }

  .applications {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}
