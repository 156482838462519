/* Content */
.form-container {
  background: var(--blue-light);
  margin: 40px 80px;
  border-radius: 25px;
  padding: 30px 40px;
}

.form-container.submitted {
  background: var(--border);
}

.form-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 30px 20px;
  border-bottom: 1px solid var(--grey);
}

.form-heading h2 {
  transform: translateY(-2px);
}

.form-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
}

.form-actions svg {
  cursor: pointer;
}

.form-content {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.form-content-half {
  width: 50%;
  padding: 0 20px;
}

.hourly-rate {
  position: relative;
}

.hourly-rate .currency {
  position: absolute;
  top: 17px;
  z-index: 99;
  left: 12px;
}

.hourly-rate .unit {
  position: absolute;
  top: 17px;
  z-index: 99;
  right: 15px;
}

.hourly-rate input {
  padding-left: 10px;
}
.award-input {
  transform: translateY(5px);
}

.form-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  margin-top: 40px;
}
/* Form */

.emp-container {
  margin: 40px 80px;
  padding: 30px 40px;
}

.error-notes {
  color: var(--red);
  font-size: 18px;
  padding-left: 20px;
  font-weight: 6 00;
}

.draft-button {
  margin-right: 20px;
}

.update-details-button {
  margin-right: 40px;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
/* Blocks */

.block {
  margin-top: 20px;
  border-bottom: 1px solid var(--border);
}

.block-heading {
  color: var(--blue);
  font-weight: 500;
}

.none-added {
  text-align: center;
  font-weight: 500;
  margin: 40px 0;
  opacity: 0.3;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .form-container,
  .emp-container {
    margin: 20px 40px;
    padding: 30px 20px;
  }

  .form-details {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .form-content {
    flex-direction: column;
    padding-top: 10px;
  }

  .form-content-half {
    width: 100%;
    padding: 0 10px;
  }
  .update-details-button {
    margin-right: 10px;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .form-container,
  .emp-container {
    margin: 80px 20px 20px 20px;
    padding: 30px 10px;
    position: relative;
  }

  .emp-container {
    margin-top: 20px;
  }

  .form-actions {
    position: absolute;
    top: -55px;
    right: 20px;
  }

  .form-buttons {
    flex-direction: column;
    justify-content: center;
  }

  .error-notes {
    margin-bottom: 30px;
  }

  .form-buttons button {
    margin: 10px 0;
  }

  .draft-button {
    margin-right: 00px;
  }
  .update-details-button {
    margin-right: 60px;
  }
}
