.container {
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.logo {
  width: 150px;
}

.login-form-input {
  background: var(--hover);
}

/* Login */

.login-left,
.login-right {
  width: 50%;
}

.login-illustration {
  width: 100%;
  height: 100%;
}

.login-left {
  text-align: center;
  padding: 40px 80px;
}

.login-form {
  background: var(--blue-light);
  border-radius: 25px;
  padding: 60px 40px 40px 40px;
}

.login-divider {
  margin: 40px 0;
  height: 1px;
  background: var(--border);
}

.login-form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.login-switch span {
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 15px;
}

.forgot-password-link {
  text-decoration: underline;
  cursor: pointer;
}

.sign-in-button {
  position: absolute;
  top: -68px;
  right: 5px;
}

.login-buttons {
  margin-top: 40px;
}

.reset-details {
  margin-top: 80px;
  text-align: left;
}

.reset-details .reset-email-text {
  margin-bottom: 60px;
}

/* Providers */

.login-provider-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 0 auto;
  width: 100%;
}

.login-provider {
  box-shadow: var(--box-shadow);
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--input-background);
  position: relative;
  border-radius: 15px;
}

.login-provider img {
  width: 30px;
  height: auto;
  margin-right: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30%;
}

.login-provider span {
  font-weight: 600;
  font-size: 17px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40%;
}

.login-provider:hover {
  cursor: pointer;
  background: rgb(231, 231, 231);
}

.login-provider-google {
  grid-area: 1 / 1 / 2 / 4;
}

.login-provider-xero {
  grid-area: 2 / 1 / 2 / 4;
}

/* Sign Up */

.signup-form-container {
  width: 70%;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 80px;
}

.signup-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signup-form {
  background: var(--blue-light);
  border-radius: 25px;
  padding: 10px 40px 20px 40px;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .login-right {
    display: none;
  }

  .login-left {
    width: 100%;
  }

  .login-form {
    padding: 60px 40px 40px 40px;
  }

  .signup-form-container {
    width: 100%;
  }

  .signup-form {
    padding: 5px 40px 40px 40px;
  }
}

/* Tablet Landscape*/
@media (max-width: 1366px) and (orientation: landscape) {
  .container {
    align-items: center;
  }

  .login-provider {
    height: 60px;
  }
  .login-provider img {
    width: 25px;
    left: 10%;
  }
  .login-provider span {
    font-size: 16px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .signup-button-container p {
    text-align: left;
  }

  .signup-button-container .login-switch span {
    margin-left: 0px;
    margin-top: 5px;
    display: block;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .login-left {
    padding: 20px;
    padding-top: 40px;
  }

  .login-provider {
    height: 60px;
  }

  .login-provider img {
    width: 20px;
    left: 10%;
  }

  .login-provider span {
    font-size: 14px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
  }

  .login-divider {
    margin: 20px 0;
  }

  .login-form {
    padding: 40px 20px 40px 20px;
  }

  .signup-form-container {
    padding: 20px;
  }

  .signup-button-container p {
    text-align: left;
  }

  .signup-button-container .login-switch span {
    margin-left: 0px;
    margin-top: 5px;
    display: block;
  }

  .signup-form {
    padding: 5px 20px 40px 20px;
  }

  .sign-in-button {
    top: -66px;
  }
}
