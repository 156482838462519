.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: white;
  padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  color: var(--blue-dark);
  font-size: 14px;
  margin: 10px 0;
}
.container:hover {
  background: #f8f8f8;
}

.name {
  width: 20%;
  font-size: medium;
}

.success,
.pending,
.failed {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}

.success .icon {
  background: var(--green);
}

.pending .icon {
  background: var(--orange);
}

.failed .icon {
  background: var(--red);
}

.more-menu,
.select-arrow {
  font-size: 18px;
}

.more-menu {
  position: relative;
}

.submenu {
  position: absolute;
  top: 20px;
  right: 0;
  background: white;
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  z-index: 11;
  cursor: auto;
  display: none;
}

.visible {
  display: block;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: none;
}

.overlay-visible {
  display: block;
}

.sensitive,
.disconnect {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
}

.sensitive p,
.disconnect p {
  flex: 1;
  white-space: nowrap;
  margin: 0 10px;
}

.disconnect {
  color: var(--red);
  cursor: pointer;
}

.disconnect:hover {
  background: #f8f8f8;
}

.submenu svg {
  width: 20px;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
}

/* Mobile */
@media (max-width: 768px) {
  .success,
  .pending,
  .failed,
  .more-menu,
  .count {
    display: none;
  }

  .name {
    width: auto;
    font-size: 14px;
  }
}
