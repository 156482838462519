.container {
  padding: 40px 80px;
}

.dash-header {
  margin: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dash-header h1 {
  margin: 20px 0;
}

.return {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.return-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue-light);
  color: var(--blue-dark);
  margin-right: 20px;
  width: 35px;
  height: 35px;
}

.return p {
  font-weight: 600;
}

.plans-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 40px;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .container {
    padding: 40px 40px;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .container {
    padding: 10px 10px;
  }

  .plans-container {
    flex-direction: column;
    align-items: center;
  }

}
