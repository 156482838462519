/* Form */
.form-container {
  background: var(--blue-light);
  margin: 40px 80px;
  border-radius: 25px;
  padding: 30px 40px;
}

.form-flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 60px 0;
}

.form-flex-container .form-input-container {
  margin: 0;
}

.form-flex-container .form-input-container:first-child {
  margin-right: 40px;
}
.form-input-container {
  width: 100%;
  margin: 60px 0 0 0;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .form-container {
    margin: 40px 20px;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .form-container {
    margin: 20px 20px;
    padding: 20px 0;
  }
}

/* Blocks */

.block {
  margin-top: 20px;
  border-bottom: 1px solid var(--border);
}

.block:first-child {
  margin-top: 0;
}

.block-heading {
  color: var(--blue);
  font-weight: 500;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
}

/* Mobile */
@media (max-width: 768px) {
  .form-flex-container {
    display: block;
    margin: 40px 0;
  }

  .form-flex-container .form-input-container:first-child {
    margin-right: 0px;
  }

  .form-flex-container .form-input-container {
    margin: 40px 0 0 0;
  }

  .form-input-container {
    width: 100%;
    margin: 40px 0 0 0;
  }
}
