.tag {
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
}

.small {
  font-size: 14px;
  line-height: 14px;
}

.large {
  font-size: 18px;
  line-height: 15px;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

@media (max-width: 768px) {
  .tag {
    font-size: 14px;
    line-height: 15px;
  }

  .small {
    font-size: 12px;
    line-height: 14px;
  }

  .large {
    font-size: 16px;
    line-height: 15px;
  }
}
