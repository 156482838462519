/* Background */
.container {
  padding: 40px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 50px;
}

.heading-details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-status {
  display: flex;
  align-items: center;
  transform: translateY(5px);
  margin-left: 40px;
}

.heading-status .icon {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background: var(--green);
}

.fa-icon {
  font-size: 25px;
  cursor: pointer;
}

.heading-divider {
  margin-bottom: 0px;
  background-color: var(--border);
  border: none;
  height: 1px;
}

.content {
  padding: 40px;
}

.content-top {
  display: flex;
  align-items: flex-start;
}

.content-left,
.content-right {
  width: 50%;
  padding: 0 20px;
}

.content-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.content-bottom .block {
  width: 50%;
}

.block-heading {
  color: var(--blue);
  font-weight: 500;
}
.content-bottom .block-content {
  padding: 0 20px;
}

/* Form */

.form-flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 60px 0;
}

.form-flex-container .form-input-container {
  margin: 0;
}

.form-flex-container .form-input-container:first-child {
  margin-right: 40px;
}
.form-input-container {
  width: 100%;
  margin: 60px 0;
}

.none-added {
  text-align: center;
  font-weight: 500;
  margin: 40px 0;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .heading {
    margin: 20px 40px;
  }
  .container {
    padding: 20px 0px;
  }
  .content {
    padding: 20px;
  }

  .content-top {
    flex-direction: column;
  }

  .content-left,
  .content-right {
    width: 100%;
    padding: 0 20px;
  }

  .content-bottom {
    padding: 20px;
  }
  .content-bottom .block-content {
    padding: 0 0px;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .heading {
    margin: 0;
    padding: 20px 30px;
  }

  .heading-details {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .heading-status {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-top: 5px;
  }
  .content {
    padding: 20px 10px;
  }

  .content-bottom {
    flex-direction: column;
  }
  .content-bottom .block {
    width: 100%;
  }
}
