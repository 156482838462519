/* Navbar */
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-shadow: var(--box-shadow);
  height: 80px;
  border-top: 10px solid var(--blue);
  width: 100%;
  z-index: 100;
  position: fixed;
  background: white;
}

.navbar-container {
  display: flex;
  align-items: center;
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-item {
  cursor: pointer;
  margin: 0 20px;
}

.active {
  color: var(--blue);
  font-weight: 500;
}

/* Switch Organisation */

.switch-container {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch-button {
  background: var(--blue-dark);
  color: white;
  padding: 10px 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.switch-button:hover {
  background: var(--blue);
}

.switch-button p {
  margin-right: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.switch-button svg {
  width: 12px;
}

.switch-selector-container {
  position: fixed;
  top: 80px;
  left: 0;
  transform: translateY(-100%);
  background: white;
  box-shadow: var(--box-shadow);
  z-index: 99;
  width: 20vw;
  min-width: 250px;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: scroll;
  transition: all 200ms ease;
  visibility: hidden;
}

.switch-selector-container.open,
.profile-container.open,
.actions-container.open {
  transform: translateY(0);
  visibility: visible;
}

.switch-selector,
.profile-item,
.action-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 40px;
  cursor: pointer;
}

.switch-selector:hover,
.switch-new:hover,
.switch-all:hover,
.profile-item:hover {
  background: var(--blue-light);
}

.switch-selector p,
.switch-new p {
  font-weight: 500;
  margin-left: 30px;
}

.switch-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: var(--blue-dark);
  color: white;
}

.switch-new {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 40px;
  cursor: pointer;
  border-top: 2px solid var(--border);
}

.switch-new-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--blue-light);
  color: var(--blue);
}

.switch-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 40px;
  cursor: pointer;
  background: var(--border);
}

.switch-all p {
  font-weight: 500;
}

/* Console */
.console-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.grid-container {
  margin: 0 30px;
  cursor: pointer;
}

.user-icon {
  background: var(--blue-dark);
  border-radius: 50%;
  color: white;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-icon-chevron {
  margin-left: 10px;
  color: var(--blue-dark);
  cursor: pointer;
  width: 12px;
}

/* Profile */

.profile-container {
  position: fixed;
  top: 80px;
  right: 0;
  transform: translateY(-100%);
  background: white;
  box-shadow: var(--box-shadow);
  z-index: 99;
  width: 20vw;
  min-width: 250px;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: scroll;
  transition: all 200ms ease;
  visibility: hidden;
}

.user-profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-item {
  justify-content: space-between;
}

.profile-icon {
  background: var(--blue-light);
  color: var(--blue);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.profile-item p {
  font-weight: 500;
}

/* Actions */

.actions-container {
  position: fixed;
  top: 80px;
  right: 115px;
  transform: translateY(-100%);
  background: white;
  box-shadow: var(--box-shadow);
  z-index: 99;
  min-width: 250px;
  max-height: 80vh;
  overflow-y: scroll;
  transition: all 200ms ease;
  visibility: hidden;
}

.action-item {
  justify-content: space-between;
}

.action-icon {
  background: var(--blue-light);
  color: var(--blue);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0 20px;
}

.profile-item p {
  font-weight: 500;
}

/* Mobile Nav */
.mobile-plus-icon,
.mobile-grid-icon {
  color: var(--blue);
  font-size: 20px;
}

.mobile-nav-container {
  transition: 200ms ease all;
  position: fixed;
  top: 80px;
  left: 0;
  width: 80%;
  height: calc(100vh - 80px);
  background: var(--blue-light);
  z-index: 101;
  transform: translateX(-100%);
}

.showNav {
  transform: translateX(0);
}

.mobile-nav-content {
  padding: 20px;
  color: var(--blue-dark);
  font-weight: 500;
}

.mobile-nav-heading {
  margin: 40px 0;
}

.mobile-nav-item {
  margin: 30px 0;
}

.mobile-nav-heading span {
  font-weight: 700;
}

.mobile-nav-item.mob-active {
  font-weight: 800;
}

.nav-actions {
  margin: 60px 0;
}

.mobile-nav-action {
  margin: 30px 0;
  font-weight: 600;
}

.mobile-nav-action:nth-child(1) {
  color: var(--blue);
  font-weight: 700;
}
