.progress-pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-control {
  background: var(--blue-light);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
  margin: 0 10px;
  cursor: pointer;
}

.page-counter h3 {
    font-weight: 500;
}

