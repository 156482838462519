.confirm-text {
  margin: 20px 10px 40px 10px;
  font-weight: 500;
}

.modal-btns-container {
  width: 100%;
}

.modal-btns-container button {
  width: 100%;
  margin: 5px 0;
  padding: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 16px;
}

.modal-class {
  min-width: 30vw;
  max-width: 600px;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .modal-class {
    min-width: 75vw;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .modal-class {
    min-width: 95vw;
  }
}
