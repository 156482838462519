.tag {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}

@media (max-width: 768px) {
  .tag {
    font-size: 16px;
  }
}
