.container {
  padding-top: 80px;
  text-align: center;
  position: relative;
}

.dancing {
  width: 40vw;
  margin: 0 auto;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .container {
    padding-top: 220px;
  }
  .dancing {
    width: 60vw;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .dancing {
    width: 90vw;
    margin: 40px auto;
  }

  .container {
    padding-top: 100px;
  }
}
