/* Application */
.wrapper {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  padding-top: 100px;
  width: 50vw;
  min-width: 500px;
  margin: 0 auto;
}

/* Nav */
.nav-container {
  position: fixed;
  top: 00px;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: var(--box-shadow);
  border-top: 10px solid var(--blue);
  z-index: 100;
  background: white;
}

.nav-item {
  cursor: pointer;
  width: 100%;
}

.nav-item-label {
  text-align: center;
  font-weight: 500;
}

.nav-item.active .nav-item-label {
  color: var(--blue);
}

.progress-bar-container {
  height: 2px;
  background: var(--border);
  width: 100%;
  position: fixed;
  top: 50px;
  left: 0;
}

.progress-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.progress-bar {
  transition: all 400ms ease;
  height: 100%;
  background: var(--blue);
  border-radius: 0 25px 25px 0;
  width: 50%;
}

/* Header */
.header {
  width: 100%;
  text-align: center;
}

.header-logo {
  margin: 0 auto;
  width: 120px;
}

.header h1 {
  margin: 40px auto;
  width: 50%;
  line-height: 180%;
}

.header p {
  font-weight: 500;
}

.header-divider {
  width: 50%;
  height: 2px;
  background: var(--border);
  margin: 40px auto;
}

/* Content */

.content-wrapper {
  position: relative;
}

.no-super-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: var(--red);
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.content {
  padding: 0 20px;
}

.content-heading {
  color: var(--blue);
  font-weight: 500;
  margin: 20px 0;
}

.form-container {
  padding: 20px 40px 20px 40px;
  background: var(--blue-light);
  border-radius: 20px;
  position: relative;
}

.form-container.confirm {
  background: white;
}

.form-half {
  width: 50%;
  padding: 20px;
  height: 100%;
}

/* Control Buttons */
.control-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.back-control {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.back-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--blue);
  margin-right: 20px;
  font-weight: 500;
}

.back-control.disabled .back-indicator {
  background: var(--border);
}

.back-control.disabled {
  color: var(--grey);
}
.draft-button {
  margin-right: 20px;
}

/* Employment Details */

.fade-out {
  opacity: 0;
  transition: all 400ms ease;
  visibility: hidden;
}

.super-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.super-bin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  height: 55px;
  cursor: pointer;
}

.super-bin svg {
  width: 20px;
  height: 100%;
}

.create-new-fund {
  text-align: center;
  color: var(--blue);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.create-new-fund p {
  margin: 0 auto;
  border-bottom: 1px solid transparent;
}

.create-new-fund p:hover {
  border-bottom: 1px solid var(--blue);
}

.create-new-fund span {
  margin-right: 15px;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.super-btn-container-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.incorrect-details {
  text-align: center;
  font-weight: 500;
}

.incorrect-details span {
  font-weight: 700;
  line-height: 250%;
}

.hourly-rate {
  position: relative;
}

.hourly-rate .currency {
  position: absolute;
  top: 17px;
  z-index: 99;
  left: 12px;
}

.hourly-rate .unit {
  position: absolute;
  top: 17px;
  z-index: 99;
  right: 15px;
}

.hourly-rate input {
  padding-left: 10px;
}

/* Tax Details */

.form-tax-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-tax-flex h4 {
  margin: 0 20px;
  font-weight: 500;
  transform: translateY(30px);
}

.form-tax-flex .form-input-container {
  margin: 40px 0 20px 0;
}

/* Tablet Portrait*/
@media (max-width: 1366px) {
  .wrapper {
    max-width: auto;
  }

  .container {
    width: 100vw;
    padding-top: 100px;
    min-width: auto;
  }

  .header h1 {
    width: 75%;
  }

  .control-buttons {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .next-control {
    margin-bottom: 30px;
  }
}
/* Mobile */
@media (max-width: 768px) {

  .no-super-button {
    bottom: auto;
    top: 45px;
    font-size: 12px;
  }
  .header {
    padding: 0 40px;
  }
  .header h1 {
    width: auto;
    margin: 20px auto;
    font-size: 24px;
  }

  .content {
    padding: 0 10px;
  }

  .header-divider {
    margin-bottom: 0;
  }

  .form-container {
    padding: 20px 10px;
  }
  .next-control {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin: 30px 0;
  }

  .next-control button {
    margin: 10px 0;
  }

  .form-tax-flex {
    flex-direction: column;
  }

  .form-tax-flex h4 {
    transform: translateY(20px);
    margin: 10px 0;
  }

  .super-bin {
    position: absolute;
    right: 40px;
  }
}

/* Bank Details */

.add-bank-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.bank-account-cont {
  position: relative;
}
.bank-bin {
  position: absolute;
  height: 55px;
  cursor: pointer;
  top: -5px;
  right: 0;
}

.bank-bin svg {
  width: 20px;
  height: 100%;
}
