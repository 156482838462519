.container {
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease;
  min-height: 170px;
  min-width: 230px;
}

.container:hover {
  transform: translateY(-2px);
  cursor: pointer;
}

.container h3 {
  margin-bottom: 5px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.incomplete-subline {
  color: var(--blue);
}

.details {
  position: absolute;
  bottom: 30px;
}

.details span {
  font-weight: 700;
}

.details p {
  font-size: 15px;
}

.status-icon {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  transform: translate(5px, -2px);
}

.status-icon.draft {
  background: var(--blue);
}

.status-icon.pending {
  background: var(--orange);
}

.status-icon.failed {
  background: var(--red);
}

.status-icon.success {
  background: var(--green);
}

.status {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--blue);
}

.status.draft {
  background: var(--blue);
}

.status.pending {
  background: var(--orange);
}

.status.failed {
  background: var(--red);
}

.status.success {
  background: var(--green);
}

.icon {
  position: absolute;
  bottom: 30px;
  right: 20px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: var(--blue-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.draft {
  background: var(--blue-light);
}

.icon.pending {
  background: var(--orange-light);
}

.icon.failed {
  background: var(--red-light);
}

.icon.success {
  background: var(--green-light);
}

.icon.success img {
  width: 20px;
}
