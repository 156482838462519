.button {
  margin: 30px 0;
  width: 100%;
  padding: 15px 25px;
}

.button div {
  justify-content: center;
}

.modal {
  width: 35vw;
  min-width: 600px;
}

.manageaccess {
  position: absolute;
  top: 15px;
  right: 80px;
}


/* Tablet Portrait*/
@media (max-width: 1366px) {
}

/* Mobile */
@media (max-width: 768px) {
  .modal {
    min-width: auto;
    width: 90vw;
  }

  .manageaccess {
    display: none;
  }
}
