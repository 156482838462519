:root {
  /* Colours */
  --blue: #1a90ff;
  --blue-light: #ebf5ff;
  --blue-dark: #014c92;

  --orange: #e86303;
  --orange-light: #fff8f1;

  --red: #cb0202;
  --red-light: #ffeaea;
  --red-dark: #a80000;

  --green: #00c7a3;
  --green-light: #f2fffd;

  --light: #eaeef4;
  --dark: var(--blue-dark);
  --white: #fff;
  --black: #1a1c1c;
  --grey: #bebebe;

  /* Fonts */
  --text: "plus jakarta sans", sans-serif;
  --headings: "plus jakarta sans", sans-serif;

  /* Effects */
  --box-shadow: 0px 4px 20px 0px rgba(228, 229, 231, 0.5);
  --box-shadow-blue: 0px 4px 20px 0px #e0f0ff;
  --border: #f5f5f5;
  --hover: var(--border);
  --input-background: white;
  --input-background-dark: #f5f5f5;
  --disabled-background: #f5f5f5;

  /* Functional */
  --success-dark: #44a23c;
  --success-light: #e6f4ed;
  --error-dark: var(--red);
  --error-light: var(--red-light);
}

html {
  background: var(--blue);
}

body {
  background: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--text);
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}


/* Animation */
@keyframes inAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

/* Third Party */

/* MUI */

/* Date Field */
.MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiInputBase-input {
  font: var(--text) !important;
  font-size: 18px !important;
  padding: 13.5px 14px !important;
  font-weight: 100 !important;
}
